import React, { useState, useRef } from "react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import classNames from "classnames";
import { CaretDownIcon } from "@radix-ui/react-icons";
import DarkmodeBackUp from "./DarkmodeBackup";
import useStickyHeader from "./useStickyHeader";
import logoLight from "../../assets/images/logo/logo_white.png";
import logoDark from "../../assets/images/logo/1200x300-black.png";
import Logo from "../../elements/logo/Logo";
import { FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import NewMobileMenu from "./NewMobileMenu";

const ListItem = React.forwardRef(
  ({ className, children, title, icon, display, ...props }, forwardedRef) => (
    <li>
      <NavigationMenu.Link asChild>
        <a
          className={classNames("ListItemLink", className)}
          {...props}
          ref={forwardedRef}
        >
          <div className="ListItemHeading">
            {title}
            <span className={`navicon`}>{icon}</span>
          </div>
          <p className={`ListItemText ${display}`}>{children}</p>
        </a>
      </NavigationMenu.Link>
    </li>
  )
);
const submenuItems = {
  implants: (
    <div className="row">
      <ul className="submenu col-6" style={{ minHeight: "350px" }}>
        <ListItem
          href="/products/implant-systems/anyridge"
          title="AnyRidge"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>
        <ListItem
          href="/products/implant-systems/anyridge-incisor"
          title="ARi&reg;"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>
        <ListItem
          href="/products/implant-systems/bluediamond"
          title="BLUEDIAMOND&reg;"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>{" "}
        <ListItem
          href="/products/implant-systems/bd-cuff"
          title="BD Cuff&reg;"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>
      </ul>
      <ul className="submenu col-6" style={{ minHeight: "300px" }}>
        <ListItem
          href="/products/implant-systems/anyone"
          title="AnyOne"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>
        <ListItem
          href="/products/implant-systems/advanced-intermezzo"
          title="Adv. Intermezzo"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>
      </ul>
    </div>
  ),
  prosthetics: (
    <div className="row">
      <ul className="submenu col-6" style={{ minHeight: "350px" }}>
        <ListItem
          href="/products/prosthetics/anatomic-healing-abutment"
          title="Anatomic HA"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>
      </ul>
      <ul className="submenu col-6" style={{ minHeight: "350px" }}>
        <ListItem
          href="/products/prosthetics/axa-abutment"
          title="AXA Abutment"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>
      </ul>
    </div>
  ),
  digital: (
    <div className="row">
      <ul className="submenu col-6" style={{ minHeight: "350px" }}>
        <ListItem
          href="/digital/r2gate"
          title="R2GATE"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>
        <ListItem
          href="/digital/r2dod"
          title="R2DOD"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>
      </ul>
      <ul className="submenu col-6">
        <ListItem
          href="/digital/facegide"
          title="FACEGIDE"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>
        <ListItem
          href="https://www.megagendigital.com/"
          title="Digital Library"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>
      </ul>
    </div>
  ),
  kits: (
    <div className="row">
      <ul className="submenu col-6">
        <ListItem href="" title="Surgical Kits" style={{ fontSize: "15px" }}>
          <div className="ml--20">
            <sub>
              <Link
                to="/products/kits/ar-surgical-kit"
                style={{ color: "#8D96AB" }}
              >
                AnyRidge <span className="navicon">➜</span>
              </Link>
              <br />
              <Link
                to="/products/kits/bd-surgical-kit"
                style={{ color: "#8D96AB" }}
              >
                BLUEDIAMOND <span className="navicon">➜</span>
              </Link>
              <br />
              <Link
                to="/products/kits/arI-surgical-kit"
                style={{ color: "#8D96AB" }}
              >
                ARi&reg;
                <sup style={{ fontSize: "10px", color: "gold" }}>
                  &nbsp;&nbsp;NEW
                </sup>
                <span className="navicon">➜</span>
              </Link>
              <br />
              <Link
                to="/products/kits/ao-surgical-kit"
                style={{ color: "#8D96AB" }}
              >
                AnyOne <span className="navicon">➜</span>
              </Link>
              <br />
              <Link to="/products/kits/ai-kit" style={{ color: "#8D96AB" }}>
                Advanced Intermezzo <span className="navicon">➜</span>
              </Link>
            </sub>
          </div>
        </ListItem>
        <ListItem
          href="/products/kits/bp-kits"
          title="Bone Profiler Kits"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>{" "}
        <ListItem
          href="/products/kits/prosthetic-kits"
          title="Prosthetic Kits"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>{" "}
        <ListItem
          href="/products/kits/axa-prosthetic-kit"
          title="AXA Prosthetic Kits"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>{" "}
      </ul>
      <ul className="submenu col-6">
        <ListItem
          href="/products/kits/r2-kits"
          title="Guided Kits"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>{" "}
        <ListItem
          href="/products/kits/pet-kit"
          title="Partial Extraction Kit"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>{" "}
        <ListItem
          href="/products/kits/sinus-kits"
          title="Sinus Elevation Kits"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>{" "}
        <ListItem
          href="/products/kits/meggyver-kit"
          title="MegGyver Kit"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>{" "}
        <ListItem
          href="/products/kits/bonex-kit"
          title="BonEx Kit"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>
      </ul>
    </div>
  ),
  equipment: (
    <div className="row">
      <ul className="submenu col-6">
        <ListItem
          href="/products/equipment/n2-unit-chair"
          title="N2 Unit Chair"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>
        <ListItem
          href="/products/equipment/r2-studio-q"
          title="R2 Studio Q"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>
        <ListItem
          href="/digital-solutions#data"
          title="Intraoral Scanner"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
        >
          <div className="ml--20">
            <sub>
              <Link to="/products/equipment/r2i3" style={{ color: "#8D96AB" }}>
                R2i3{" "}
                <sup style={{ fontSize: "10px", color: "gold" }}>
                  &nbsp;&nbsp;NEW
                </sup>{" "}
                <sup style={{ fontSize: "10px", color: "red" }}>
                  &nbsp;&nbsp; HOT
                </sup>
                <span className="navicon">➜</span>
              </Link>
            </sub>
            <br />
            <sub>
              <Link to="/shining3d/scanner" style={{ color: "#8D96AB" }}>
                Aoralscan Elite{" "}
                <sup style={{ fontSize: "10px", color: "gold" }}>
                  &nbsp;&nbsp;NEW
                </sup>
                <span className="navicon">➜</span>
              </Link>
            </sub>
            <br />
            <sub>
              <Link to="/products/equipment/medit" style={{ color: "#8D96AB" }}>
                MEDIT <span className="navicon">➜</span>
              </Link>
            </sub>
          </div>
        </ListItem>
        <ListItem
          href="/products/equipment/meg-engine"
          title="Engines"
          style={{ fontSize: "15px" }}
        >
          <div className="ml--20">
            <sub>
              <Link
                to="/products/equipment/meg-engine"
                style={{ color: "#8D96AB" }}
              >
                MEG Engine II <span className="navicon">➜</span>
              </Link>
            </sub>
            <br />
            <sub>
              <Link
                to="/products/equipment/meg-engine"
                style={{ color: "#8D96AB" }}
              >
                MEG Engine III <span className="navicon">➜</span>
              </Link>
            </sub>
            <br />
            <sub>
              <Link
                to="/products/equipment/meg-engine"
                style={{ color: "#8D96AB" }}
              >
                MEG Engine III Pro <span className="navicon">➜</span>
              </Link>
            </sub>
          </div>
        </ListItem>
      </ul>
      <ul className="submenu col-6">
        <ListItem
          href="/products/equipment/plasmax-motion"
          title="Plasma X Motion"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>
        <ListItem
          href="/products/equipment/mega-isq-ii"
          title="MEGA ISQ II"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>
        <ListItem
          href="/products/equipment/meg-torq"
          title="MEG TorQ"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>{" "}
        <ListItem
          href="/digital-solutions#printing"
          title="Shining 3D Printer"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
        >
          <div className="ml--20">
            <sub>
              <Link to="/shining3d/printers" style={{ color: "#8D96AB" }}>
                AccuFab-CEL/L4D{" "}
                <sup style={{ fontSize: "10px", color: "gold" }}>
                  &nbsp;&nbsp;NEW
                </sup>{" "}
                <sup style={{ fontSize: "10px", color: "red" }}>
                  &nbsp;&nbsp; HOT
                </sup>
                <span className="navicon">➜</span>
              </Link>
            </sub>
            <br />
            <sub>
              <Link to="/shining3d/fabwash" style={{ color: "#8D96AB" }}>
                FabWash
                <sup style={{ fontSize: "10px", color: "gold" }}>
                  &nbsp;&nbsp;NEW
                </sup>
                <span className="navicon">➜</span>
              </Link>
            </sub>
            <br />
            <sub>
              <Link to="/shining3d/fabcure-2" style={{ color: "#8D96AB" }}>
                FabCure 2 <span className="navicon">➜</span>
              </Link>
            </sub>
          </div>
        </ListItem>
      </ul>
    </div>
  ),
  regenerative: (
    <div>
      <ul className="submenu " style={{ minHeight: "350px" }}>
        <ListItem
          href="/products/regenerative/igen"
          title="iGen Membrane"
          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
          style={{ fontSize: "15px" }}
          display="d-none"
        ></ListItem>
      </ul>
    </div>
  ),
};
const HeaderNew = ({ btnStyle, HeaderSTyle }) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const [submenuContent, setSubmenuContent] = useState(submenuItems.implants);

  const handleHover = (content) => {
    setSubmenuContent(content);
  };

  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };
  const ref = useRef();
  const check = true;
  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky && check ? "sticky" : ""}`;

  return (
    <header
      ref={ref}
      className={`header header-default ${HeaderSTyle} ${headerClasses}`}
    >
      <div className=" position-relative">
        <div className="row align-items-center row--0">
          <div className="col-lg-2 col-md-6 col-4">
            <Logo image={logoLight} image2={logoDark} className={"ml--50"} />
          </div>
          <div className="col-lg-10 col-md-6 col-8 position-static">
            <div class="">
              <NavigationMenu.Root className="NavigationMenuRoot d-none d-xl-block">
                <NavigationMenu.List className="NavigationMenuList">
                  <NavigationMenu.Item
                    style={{ marginLeft: "20px", marginRight: "20px" }}
                  >
                    <NavigationMenu.Trigger
                      className="NavigationMenuTrigger"
                      style={{ fontSize: "16px" }}
                    >
                      Products{" "}
                      <CaretDownIcon className="CaretDown" aria-hidden />
                    </NavigationMenu.Trigger>
                    <NavigationMenu.Content
                      className="NavigationMenuContent"
                      onMouseLeave={() => handleHover("")}
                    >
                      <ul className="List one ">
                        <li
                          style={{
                            gridRow: "span 3",
                            borderRadius: "5px",
                            backgroundColor: "white",
                          }}
                        >
                          <ListItem
                            href="/products/implant-systems"
                            title="Implant Systems"
                            className="product"
                            onMouseEnter={() =>
                              handleHover(submenuItems.implants)
                            }
                            display="d-none"
                          ></ListItem>
                          <ListItem
                            href="/products/prosthetics"
                            title="Prosthetics"
                            className="product"
                            onMouseEnter={() =>
                              handleHover(submenuItems.prosthetics)
                            }
                            display="d-none"
                          ></ListItem>
                          <ListItem
                            href="#"
                            title="Digital"
                            className="product"
                            onMouseEnter={() =>
                              handleHover(submenuItems.digital)
                            }
                            display="d-none"
                          ></ListItem>
                          <ListItem
                            href="/products/kits"
                            className="product "
                            title="MegaGen Kits"
                            onMouseEnter={() => handleHover(submenuItems.kits)}
                            display="d-none"
                          ></ListItem>{" "}
                          <ListItem
                            href="/products/equipment"
                            className="product "
                            title="Equipment"
                            onMouseEnter={() =>
                              handleHover(submenuItems.equipment)
                            }
                            display="d-none"
                          ></ListItem>
                          <ListItem
                            href="/products/regenerative/igen"
                            className="product "
                            title="Regenerative"
                            onMouseEnter={() =>
                              handleHover(submenuItems.regenerative)
                            }
                            display="d-none"
                          ></ListItem>{" "}
                        </li>

                        <li style={{ backgroundColor: "white" }}>
                          {submenuContent}
                        </li>
                      </ul>
                    </NavigationMenu.Content>
                  </NavigationMenu.Item>
                  <NavigationMenu.Item
                    style={{ marginLeft: "20px", marginRight: "20px" }}
                  >
                    <NavigationMenu.Trigger
                      className="NavigationMenuTrigger"
                      style={{ fontSize: "16px" }}
                    >
                      Solutions
                      <CaretDownIcon className="CaretDown" aria-hidden />
                    </NavigationMenu.Trigger>
                    <NavigationMenu.Content className="NavigationMenuContent">
                      <ul
                        className="List two"
                        style={{ backgroundColor: "white" }}
                      >
                        <ListItem
                          title="Digital Solutions"
                          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
                          href="/digital-solutions"
                        >
                          Revolutionizing Dentistry with Comprehensive Digital
                          Solutions.
                        </ListItem>
                        <ListItem
                          title={
                            <>
                              Accelerated Loading
                              <sup style={{ fontSize: "10px", color: "blue" }}>
                                &nbsp;&nbsp;Coming Soon
                              </sup>
                            </>
                          }
                          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
                          // href="/accelerated-loading"
                          href="#"
                        >
                          Innovative design provides a "safe zone" for immediate
                          loading protocols, providing reliable results quickly.
                        </ListItem>{" "}
                        <ListItem
                          title="MINEC America"
                          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
                          href="https://www.minecamerica.com"
                        >
                          Clinical Education
                        </ListItem>
                        <ListItem
                          title="Immediate Loading"
                          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
                          href="/immediate-molar"
                        >
                          Solution-based implantology for the best aesthetic and
                          predictable molars.
                        </ListItem>{" "}
                        <ListItem
                          title={
                            <>
                              R2 Full Arch{" "}
                              <sup style={{ fontSize: "10px", color: "blue" }}>
                                &nbsp;&nbsp;Coming Soon
                              </sup>
                            </>
                          }
                          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
                          href="#"
                        >
                          Digital Precision for Predictable, Efficient
                          Restorations
                        </ListItem>{" "}
                      </ul>
                    </NavigationMenu.Content>
                  </NavigationMenu.Item>
                  <NavigationMenu.Item
                    style={{ marginLeft: "20px", marginRight: "20px" }}
                  >
                    <NavigationMenu.Trigger
                      className="NavigationMenuTrigger"
                      style={{ fontSize: "16px" }}
                    >
                      About
                      <CaretDownIcon className="CaretDown" aria-hidden />
                    </NavigationMenu.Trigger>
                    <NavigationMenu.Content className="NavigationMenuContent">
                      <ul
                        className="List three"
                        style={{ backgroundColor: "white" }}
                      >
                        {" "}
                        <ListItem
                          title="About Us"
                          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
                          href="/about-us"
                          display="d-none"
                        ></ListItem>
                        <ListItem
                          title="Why MegaGen?"
                          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
                          href="/why-megagen"
                          display="d-none"
                        ></ListItem>
                        <ListItem
                          title="Manufacturing"
                          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
                          href="/manufacturing"
                          display="d-none"
                        ></ListItem>
                        <ListItem
                          title="Quality & Safety"
                          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
                          href="/safety"
                          display="d-none"
                        ></ListItem>
                        <ListItem
                          title="Careers"
                          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
                          href="/careers"
                          display="d-none"
                        ></ListItem>
                      </ul>
                    </NavigationMenu.Content>
                  </NavigationMenu.Item>{" "}
                  <NavigationMenu.Item
                    style={{ marginLeft: "20px", marginRight: "20px" }}
                  >
                    <NavigationMenu.Trigger
                      className="NavigationMenuTrigger"
                      style={{ fontSize: "16px" }}
                    >
                      Contact
                      <CaretDownIcon className="CaretDown" aria-hidden />
                    </NavigationMenu.Trigger>
                    <NavigationMenu.Content className="NavigationMenuContent">
                      <ul
                        className="List four"
                        style={{ backgroundColor: "white" }}
                      >
                        <ListItem
                          title="Contact Us"
                          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
                          href="/contact"
                          display="d-none"
                        ></ListItem>
                        <ListItem
                          title="Local Sales Rep"
                          icon={<FaArrowRight style={{ fontSize: "11px" }} />}
                          href="/salesrep"
                          display="d-none"
                        ></ListItem>
                      </ul>
                    </NavigationMenu.Content>
                  </NavigationMenu.Item>
                  <NavigationMenu.Item
                    style={{ marginLeft: "20px", marginRight: "20px" }}
                  >
                    <NavigationMenu.Link
                      className="NavigationMenuLink"
                      href="/downloads"
                      style={{ fontSize: "16px" }}
                    >
                      Download
                    </NavigationMenu.Link>
                  </NavigationMenu.Item>
                  <NavigationMenu.Item
                    style={{ marginLeft: "20px", marginRight: "20px" }}
                  >
                    <NavigationMenu.Link
                      className="NavigationMenuLink"
                      href="/patients"
                      style={{ fontSize: "16px" }}
                    >
                      Patient FAQ
                    </NavigationMenu.Link>
                  </NavigationMenu.Item>
                  <div
                    style={{
                      alignContent: "center",
                    }}
                  >
                    <div className="d-none">
                      <DarkmodeBackUp />
                    </div>
                  </div>
                  <NavigationMenu.Indicator className="NavigationMenuIndicator">
                    <div className="Arrow" />
                  </NavigationMenu.Indicator>
                </NavigationMenu.List>
                <div className="ViewportPosition">
                  <NavigationMenu.Viewport className="NavigationMenuViewport" />
                </div>{" "}
              </NavigationMenu.Root>
            </div>{" "}
            <div className="header-right mr--20">
              <div className="mobile-menu-bar ml--5 d-block d-xl-none">
                <div className="hamberger">
                  <span className="hamberger-button" onClick={onCanvasHandler}>
                    <FiMenu />
                  </span>
                </div>
              </div>{" "}
              <NewMobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </header>
  );
};

export default HeaderNew;
