import React from "react";
import Logo from "../../elements/logo/Logo";
import { FiX } from "react-icons/fi";
import logoLight from "../../assets/images/logo/1200x300-black.png";
import logoDark from "../../assets/images/logo/logo_white.png";
import NewMobile from "./NewMobile";

const NewMobileMenu = ({ show, onClose }) => {
  var elements = document.querySelectorAll(
    ".popup-mobile-menu .has-dropdown > a"
  );
  var elementsTwo = document.querySelectorAll(
    ".popup-mobile-menu .with-megamenu > a"
  );
  for (let i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function() {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  for (let j in elementsTwo) {
    if (elementsTwo.hasOwnProperty(j)) {
      elementsTwo[j].onclick = function() {
        this.parentElement
          .querySelector(".megamenu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  return (
    <div className={`popup-mobile-menu ${show ? "active" : ""}`}>
      <div className="inner">
        <div className="header-top">
          <Logo image={logoDark} image2={logoLight} />
          <div className="close-menu">
            <span className="close-button" onClick={onClose}>
              <FiX />
            </span>
          </div>
        </div>
        <NewMobile />
      </div>
    </div>
  );
};
export default NewMobileMenu;
