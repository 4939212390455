import React from "react";
import Layout from "../common/Layout";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import { Helmet } from "react-helmet";
import GoogleTag from "../elements/pageSections/googletag/GoogleTag";
import BreadcrumbNew from "../elements/breadcrumb/BreadcrumbNew";

const Return = () => {
  return (
    <>
      <GoogleTag />
      <Helmet>
        <title>Return Policy - MegaGen America</title>
      </Helmet>
      <Layout>
        <BreadcrumbNew
          title="Return Policy"
          breadcrumb={[
            { link: "/", title: "Home" },
            { link: "/return", title: "Return Policy" },
          ]}
        />
        <div className="container mt--100">
          <div className="row g-5">
            <div className="content">
              <h4 className="title text-center" style={{ fontSize: "30px" }}>
                MegaGen America Return Policy
              </h4>
              <p>
                All returns to MegaGen America must include a completed RMA
                form. Returns without a proper RMA form will be shipped back to
                the customer. MegaGen America allows return requests within 30
                days of the original invoice date. A return shipping label can
                be provided for an additional fee; otherwise, all return
                shipping charges and tracking are the customer’s responsibility.
                All submitted returns are subject to review and final approval
                by MegaGen America, which reserves the right to reject and
                return any items not in compliance with the guidelines below.
                There will be a 1-week period between notices, and items will be
                sent back a week after the final notice.
              </p>

              <h4 className="title text-center">Return Eligibility</h4>
              <img
                className="col-12"
                src="/images/img/Company/Return/return.png"
              />
              <br />
              <br />
              <p>
                Only products that are unopened, unmarked, undamaged, and
                unaltered will be considered for return or exchange. In some
                cases, damaged or marked items may be accepted at the discretion
                of MegaGen America with proper authorization. Items accepted in
                such cases will only be eligible for credit; otherwise, they
                will be shipped back to the customer with an explanation letter.
              </p>
              <p>
                All return items not purchased directly through MegaGen America
                will be automatically sent back to the customer. Any returned
                products that fail to meet our return guidelines will also be
                automatically sent back.{" "}
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Return;
