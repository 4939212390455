import React from "react";
import { Link } from "react-router-dom";
import purify from "dompurify";

const BreadcrumbOne = ({ title, rootUrl, parentUrl, currentUrl }) => {
  return (
    <div className="breadcrumb-area breadcrumb-style-1 ptb--120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-inner text-center">
              <h1
                className="title theme-gradient h2"
                dangerouslySetInnerHTML={{ __html: purify.sanitize(title) }}
              ></h1>
              <ul className="page-list">
                <li className="breadcrumb-item">
                  <Link
                    to={`${rootUrl}`}
                    dangerouslySetInnerHTML={{
                      __html: purify.sanitize(parentUrl),
                    }}
                  ></Link>
                </li>{" "}
                <li
                  className="breadcrumb-item active"
                  dangerouslySetInnerHTML={{
                    __html: purify.sanitize(currentUrl),
                  }}
                ></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbOne;
