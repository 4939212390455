import DemoForm from "../../form/DemoForm";
import React, { useState } from "react";
const DemoHero = ({ className, sendEmail }) => {
  const [checked, setChecked] = useState("implants");
  return (
    <section
      className={`mgga-hero-area pt--100 ${className || ""}`}
      style={{ background: "linear-gradient(to bottom, #007BFF, #E0FFFF)" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="mgga-hero-content">
              <span
                style={{
                  fontSize: "25px",
                  fontWeight: 800,
                  color: "#C0C0C0",
                }}
              ></span>
              <h1 className="mgga-title" style={{ color: "white" }}>
                Request More Information
              </h1>
              <h4 style={{ color: "white" }}>
                Fill out the form to get connected to our specialists.
              </h4>

              <ul style={{ listStyle: "none", marginTop: 0 }}>
                <li>
                  <input
                    id="implants"
                    type="checkbox"
                    checked={checked === "implants"}
                    onChange={() => setChecked("implants")}
                  />
                  <label
                    htmlFor="implants"
                    id="implant-checkbox"
                    style={{ color: "white" }}
                  >
                    Implants
                  </label>
                </li>
                <li></li>
                <li></li>
                <li></li>
                <li>
                  <input
                    id="equipment"
                    type="checkbox"
                    checked={checked === "equipment"}
                    onChange={() => setChecked("equipment")}
                  />
                  <label
                    id="equipment-checkbox"
                    htmlFor="equipment"
                    style={{ color: "white" }}
                  >
                    Equipment
                  </label>
                </li>
              </ul>

              <DemoForm sendEmail={sendEmail} category={checked} />
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="mgga-hero-thumb">
              <div
                className="thumb wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <video
                  muted
                  autoPlay
                  loop
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                  style={{
                    display: "inline-block",
                    width: "70%",
                    borderRadius: "5px",
                    marginLeft: "50px",
                  }}
                >
                  {" "}
                  <source
                    src="https://mgg-sm-portal.s3.us-east-2.amazonaws.com/file-1741036759424.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>{" "}
              <div>
                <img
                  src="/images/img/promo/demo/demo.webp"
                  alt="megagen logo"
                  style={{
                    width: "300px",
                    height: "300px",
                    boxShadow: "0px 0px 0px 0px",
                  }}
                  className="thumb-2 wow animated fadeInRight"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DemoHero;
