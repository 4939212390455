import React from "react";
import "../assets/css/style.css";
import Layout from "../common/Layout";
import emailjs from "emailjs-com";
import GoogleTag from "../elements/pageSections/googletag/GoogleTag";
import { Helmet } from "react-helmet";
import DemoHero from "../elements/pageSections/banner/DemoHero";
function DemoPage() {
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_k12vyev",
        "template_jm9o9v9",
        e.target,
        "Xa6kD888o1i99PsPn"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      )
      .then(e.target.reset())
      .then(window.open("/demo/confirmation"));
  };

  return (
    <>
      <GoogleTag />
      <Helmet>
        <title>Demo - MegaGen America</title>
      </Helmet>
      <Layout>
        <DemoHero sendEmail={sendEmail} />
      </Layout>
    </>
  );
}

export default DemoPage;
