import "../assets/css/style.css";
import BannerEl from "../elements/pageSections/banner/BannerEl";
import Layout from "../common/Layout";
import React from "react";
import SloganSection from "../elements/pageSections/banner/SloganSection";
import DigitalSolCards from "../elements/service/DigitalSolCards";
import ItemListings from "../elements/itemlisting/ItemListings";
import GoogleTag from "../elements/pageSections/googletag/GoogleTag";
import MetaData from "../elements/pageSections/helmet/Helmet";
const BannerData = [
  {
    image: "",
  },
];

const listingData = [
  [
    {
      name: "R2 Studio Q",
      slogan: "All In One Digital Workstation",
      img:
        "/images/img/Digital/Solution/25_Digital_Solutions_Details_0203-02.webp",
      alt: "R2 Studio Q CBCT",
      href: "/products/equipment/r2-studio-q",
    },
    {
      name: "R2i3",
      slogan: "New AI-driven era for intraoral scanners",
      img:
        "/images/img/Digital/Solution/25_Digital_Solutions_Details_0203-03.webp",
      alt: "R2i3 IOS",
      href: "/products/equipment/r2i3",
    },
    {
      name: "MEDIT",
      slogan: "Digital Dentistry At Your Fingertips",
      img:
        "/images/img/Digital/Solution/25_Digital_Solutions_Details_0203-04.webp",
      alt: "MEDIT IOS",
      href: "/products/equipment/medit",
    },
    {
      name: "Shining3D",
      slogan: "High-accuracy 3D Scanning Solutions",
      img:
        "/images/img/Digital/Solution/25_Digital_Solutions_Details_0203-05.webp",
      alt: "Shining3D",
      href: "/shining3d/scanner",
    },
  ],
  [
    {
      name: "R2GATE",
      slogan: "Start Of Comprehensive Dental Care",
      img:
        "/images/img/Digital/Solution/25_Digital_Solutions_Details_0203-06.webp",
      alt: "R2 GATE Software",
      href: "/digital/r2gate",
    },
    {
      name: "R2DOD",
      slogan: "",
      img:
        "/images/img/Digital/Solution/25_Digital_Solutions_Details_0203-06.webp",
      alt: "R2 GATE Software",
      href: "/digital/r2dod",
    },
    {
      name: "FACEGIDE",
      slogan: "",
      img: "/images/img/Digital/Solution/facegide.webp",
      alt: "R2 GATE Software",
      href: "/digital/facegide",
    },
  ],
  [
    {
      name: "AccuFab-CEL",
      slogan: "Higher, Faster, Easier",
      img:
        "/images/img/Digital/Solution/25_Digital_Solutions_Details_0203-07.webp",
      alt: "AccuFab-CEL",
      href: "/shining3d/printers",
    },
    {
      name: "AccuFab-L4D/K",
      slogan: "Connecting the Future",
      img:
        "/images/img/Digital/Solution/25_Digital_Solutions_Details_0203-08.webp",
      alt: "AccuFab-L4D/K",
      href: "/shining3d/printers",
    },
    {
      name: "FabWash",
      slogan: "Smarter Post-processing",
      img:
        "/images/img/Digital/Solution/25_Digital_Solutions_Details_0203-09.webp",
      alt: "Fab Wash",
      href: "/shining3d/fabwash",
    },
    {
      name: "FabCure 2",
      slogan: "Fabulous Curing",
      img:
        "/images/img/Digital/Solution/25_Digital_Solutions_Details_0203-10.webp",
      alt: "Fab Cure 2",
      href: "/shining3d/fabcure-2",
    },
  ],
  [
    {
      name: "Guided Surgical Kits",
      slogan: "AR / BD / AO Guided Kits",
      img:
        "/images/img/Digital/Solution/25_Digital_Solutions_Details_0203-11.webp",
      alt: "Guided Surgical Kits MegaGen",
      href: "/products/kits/r2-kits",
    },
    {
      name: "R2 Milling Center",
      slogan: "Prosthetic Solutions",
      img:
        "/images/img/Digital/Solution/25_Digital_Solutions_Details_0203-12.webp",
      alt: "R2 milling center",
      href: "https://www.r2milling.com/",
    },
  ],
];
const DigitalSolutions = () => {
  const loaded = "/images/img/Digital/Solution/25_Digital_Solutions_0103.webp";
  const description =
    "Discover MegaGen America's cutting-edge digital solutions, including digital workflows, guided surgery, and CAD/CAM technology. Enhance precision, efficiency, and patient outcomes with innovative dental solutions tailored for modern implantology.";
  const keywords =
    "MegaGen, MegaGen America, MegaGen Digital Solutions, Digital Workflow, Guided Surgery, CAD/CAM Technology, Digital Dentistry, Implant Planning, Dental Innovation, Precision Dentistry, Advanced Dental Solutions";
  const title = "Digital Solutions - MegaGen America";
  const link = "https://megagenamerica.com/digital-solutions";
  return (
    <>
      <GoogleTag />
      <MetaData
        description={description}
        title={title}
        keywords={keywords}
        link={link}
      />
      <Layout>
        <BannerEl loaded={loaded} bannerData={BannerData} />
        <SloganSection
          slogan={
            "Revolutionizing Dentistry with Comprehensive Digital Solutions"
          }
          product={"Digital Solutions"}
          description={
            "Experience seamless integration from data acquisition to final prosthetic outcomes. Our cutting-edge technology combines precision, efficiency, and advanced AI capabilities to transform your dental practice, delivering exceptional patient care and streamlined workflows."
          }
          alt={"Digital Solutions"}
          secondaryAlt={""}
          visibility={false}
          pdfLink={""}
          image={
            "/images/img/Digital/Solution/25_Digital_Solutions_Details_0203-01.webp"
          }
        />
        <DigitalSolCards />
        {/* add sections for each so would need 4 full sections */}
        {/* make it dynamic component so i can feed it json data and it can auto populate */}
        <ItemListings
          category="Data Acquisition"
          prodClass="data"
          description="Quality Scans for Quality Results."
          data={listingData[0]}
          bgColor={"#"}
        />
        <ItemListings
          prodClass="planning"
          category="Planning & Design"
          description="Planning & Designing Success, Step by Step"
          data={listingData[1]}
          bgColor={"#"}
        />
        <ItemListings
          prodClass="printing"
          category="Printing"
          description="From Digital Design to Tangible Perfection"
          data={listingData[2]}
          bgColor={"#"}
        />
        <ItemListings
          prodClass="surgical"
          category="Surgical Solutions"
          description="The Right Tools for Every Procedure"
          data={listingData[3]}
          bgColor={"#"}
        />
      </Layout>
    </>
  );
};

export default DigitalSolutions;
