import React from "react";
import { Link } from "react-router-dom";

const NewMobile = () => {
  return (
    <ul className="mainmenu">
      <li className="with-megamenu">
        <Link to="#" style={{ fontSize: "18px", fontWeight: "800" }}>
          Products
        </Link>
        <div className="megamenu">
          <div className="wrapper">
            <div className="row row--0">
              <div className="col-xl-3 single-mega-item">
                <ul className="mega-menu-item">
                  <li id="future-dropdown">
                    <Link
                      style={{ fontSize: "18px", fontWeight: "600" }}
                      to="/products/implant-systems"
                    >
                      Implant Systems
                    </Link>
                    <ul className="submenu">
                      <li>
                        <Link
                          to="/products/implant-systems/anyridge"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          AnyRidge
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/implant-systems/anyridge-incisor"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          ARi&reg;{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/implant-systems/bluediamond"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          BLUEDIAMOND&reg;
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/implant-systems/bd-cuff"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          BD Cuff&reg;{" "}
                          <sup style={{ fontSize: "10px", color: "gold" }}>
                            &nbsp;&nbsp;NEW
                          </sup>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/implant-systems/anyone"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          AnyOne
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/implant-systems/advanced-intermezzo"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Advanced Intermezzo
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/products/implant-systems/mini"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          MiNi
                        </Link>
                      </li> */}
                    </ul>
                  </li>
                  <li id="future-dropdown">
                    <Link
                      style={{ fontSize: "18px", fontWeight: "600" }}
                      to="#"
                    >
                      Prosthetics
                    </Link>
                    <ul className="submenu">
                      <li>
                        <Link
                          style={{ fontSize: "17px", fontWeight: "500" }}
                          to="/products/prosthetics/anatomic-healing-abutment"
                        >
                          Anatomic Healing Abutment
                        </Link>
                      </li>
                      <li>
                        <Link
                          style={{ fontSize: "17px", fontWeight: "500" }}
                          to="/products/prosthetics/axa-abutment"
                        >
                          AXA Abutment
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col-xl-3 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <Link
                      to="/products/kits"
                      style={{ fontSize: "18px", fontWeight: "600" }}
                    >
                      Digital
                    </Link>
                    <ul className="submenu">
                      <li>
                        <Link
                          to="/digital/r2gate"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          R2GATE
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/digital/r2dod"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          R2DOD
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/digital/facegide"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          FACEGIDE
                        </Link>
                      </li>
                      <li>
                        <a
                          href="https://www.megagendigital.com/"
                          target="_blank"
                          rel="noreferrer"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Digital Library
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col-xl-3 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <Link
                      to="/products/kits"
                      style={{ fontSize: "18px", fontWeight: "600" }}
                    >
                      MegaGen Kits
                    </Link>
                    <ul className="submenu">
                      <li>
                        <Link
                          to="/products/kits/ar-surgical-kit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          AnyRidge Surgical Kit
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/ari-surgical-kit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          ARi&reg; Surgical Kit{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/bd-surgical-kit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          BLUEDIAMOND&reg; Surgical Kit
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/ao-surgical-kit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          AnyOne Surgical Kit
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/bp-kits"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Bone Profiler Kits
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/prosthetic-kits"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Prosthetic Kits
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/axa-prosthetic-kit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          AXA Prosthetic Kits
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/ai-kit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Advanced Intermezzo Kit
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/r2-kits"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Guided Kits
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/pet-kit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Partial Extraction Kit
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/sinus-kits"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Sinus Elevation Kits{" "}
                          <sup style={{ fontSize: "10px", color: "gold" }}>
                            &nbsp;&nbsp;NEW
                          </sup>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/meggyver-kit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          MegGyver Kit{" "}
                          <sup style={{ fontSize: "10px", color: "gold" }}>
                            &nbsp;&nbsp;NEW
                          </sup>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/kits/bonex-kit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          BonEx Kit
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col-xl-3 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <Link
                      to="/products/equipment"
                      style={{ fontSize: "18px", fontWeight: "600" }}
                    >
                      Equipment
                    </Link>
                    <ul className="submenu">
                      <li>
                        <Link
                          to="/products/equipment/n2-unit-chair"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          N2 Unit Chair{" "}
                          <sup style={{ fontSize: "10px", color: "red" }}>
                            &nbsp;&nbsp; HOT
                          </sup>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/equipment/r2-studio-q"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          R2 Studio Q - CBCT
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/equipment/r2i3"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          R2i3 - IOS{" "}
                          <sup style={{ fontSize: "10px", color: "gold" }}>
                            &nbsp;&nbsp;NEW
                          </sup>{" "}
                          <sup style={{ fontSize: "10px", color: "red" }}>
                            &nbsp;&nbsp; HOT
                          </sup>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/shining3d/scanner"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Shining3D Aoralscan Elite
                          <sup style={{ fontSize: "10px", color: "gold" }}>
                            &nbsp;&nbsp;NEW
                          </sup>{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/shining3d/printers"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Shining3D 3D Printers
                          <sup style={{ fontSize: "10px", color: "gold" }}>
                            &nbsp;&nbsp;NEW
                          </sup>{" "}
                        </Link>
                      </li>{" "}
                      <li>
                        <Link
                          to="/shining3d/fabwash"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Shining3D FabWash
                          <sup style={{ fontSize: "10px", color: "gold" }}>
                            &nbsp;&nbsp;NEW
                          </sup>{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/shining3d/fabcure-2"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Shining3D FabCure 2
                          <sup style={{ fontSize: "10px", color: "gold" }}>
                            &nbsp;&nbsp;NEW
                          </sup>{" "}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/equipment/medit"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          MEDIT - IOS
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/equipment/meg-engine"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          MEG Engine
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/equipment/plasmax-motion"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Plasma X Motion
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/equipment/mega-isq-ii"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          MEGA ISQ II
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/equipment/meg-torq"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          MEG TorQ
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* <li>
                    <Link
                      to="#"
                      style={{ fontSize: "18px", fontWeight: "600" }}
                    >
                      Shining3D
                    </Link>
                    <ul className="submenu">
                      <li>
                        <Link
                          to="/shining3d/scanner"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          Aoralscan Elite & MetiSmile
                          <sup style={{ fontSize: "10px", color: "red" }}>
                            &nbsp;&nbsp; HOT
                          </sup>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/shining3d/printers"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          3D Printers
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/shining3d/fabwash"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          FabWash
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/shining3d/fabcure-2"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          FabCure 2
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </div>
              <div className="col-xl-3 single-mega-item">
                <ul className="mega-menu-item">
                  <li>
                    <Link
                      to="/products/regenerative/igen"
                      style={{ fontSize: "18px", fontWeight: "600" }}
                    >
                      Regenerative
                    </Link>
                    <ul className="submenu">
                      <li>
                        <Link
                          to="/products/regenerative/igen"
                          style={{ fontSize: "17px", fontWeight: "500" }}
                        >
                          iGen Membrane
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li className="has-dropdown">
        <Link to="#" style={{ fontSize: "18px", fontWeight: "800" }}>
          Solutions
        </Link>
        <ul className="submenu">
          <li>
            <Link
              to="/digital-solutions"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              Digital Solutions
            </Link>
          </li>
          <li>
            <Link
              to="/immediate-molar"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              Immediate Molars
            </Link>
          </li>
          <li>
            <Link
              to="/accelerated-loading"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              Accelerated Loading
            </Link>
          </li>
          <li>
            <a
              href="https://www.minecamerica.com/"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              MINEC America
            </a>
          </li>
          <li>
            <Link to="#" style={{ fontSize: "17px", fontWeight: "500" }}>
              R2 Full Arch
            </Link>
          </li>
        </ul>
      </li>

      <li className="has-dropdown">
        <Link to="#" style={{ fontSize: "18px", fontWeight: "800" }}>
          Company
        </Link>
        <ul className="submenu">
          <li>
            <Link
              to="/about-us"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              to="/why-megagen"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              Why MegaGen?
            </Link>
          </li>
          <li>
            <Link
              to="/manufacturing"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              Manufacturing
            </Link>
          </li>
          <li>
            <Link to="/safety" style={{ fontSize: "17px", fontWeight: "500" }}>
              Quality & Safety
            </Link>
          </li>

          <li>
            <Link to="/careers" style={{ fontSize: "17px", fontWeight: "500" }}>
              Careers
            </Link>
          </li>
        </ul>
      </li>
      <li className="has-dropdown">
        <Link to="#" style={{ fontSize: "18px", fontWeight: "800" }}>
          Contact
        </Link>
        <ul className="submenu">
          <li>
            <Link to="/contact" style={{ fontSize: "17px", fontWeight: "500" }}>
              Contact Us
            </Link>
          </li>
          <li>
            <Link
              to="/salesrep"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              Sales Directory{" "}
            </Link>
          </li>
          <li>
            <a
              href="tel:+8442885425"
              style={{ fontSize: "17px", fontWeight: "500" }}
            >
              (844) 288 5425
            </a>
          </li>
        </ul>
      </li>

      <li className="has-dropdown">
        <a href="/downloads" style={{ fontSize: "18px", fontWeight: "800" }}>
          Download
        </a>
      </li>
      <li className="has-dropdown">
        <a href="/patients" style={{ fontSize: "18px", fontWeight: "800" }}>
          Patient FAQ
        </a>
      </li>
    </ul>
  );
};
export default NewMobile;
